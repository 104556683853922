.marielouise {
  display: none;

  @media screen and (min-width: $laptop) {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;

    &::after,
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      content: "";
      background: $color-secondary;
    }

    &::after {
      z-index: 2;
      margin: 15px;
      background: $color-primary;
    }
  }

  @media screen and (min-width: $desktop) {
    &::after {
      margin: 20px;
    }
  }
}
