.title {
  &--level-1 {
    font-size: 2.2rem;
    font-weight: 900;
    line-height: 1.3;
    margin: 1rem;
    color: $color-secondary;
    font-family: "Merriweather", Georgia, "Times New Roman", Times, serif;
  }

  &--level-2 {
    font-size: 1.4rem;
    line-height: 1.3;
    margin: 1rem 1rem .8rem 1rem;
    font-weight: 600;

    span {
      display: block;
    }
  }

  &--level-3 {
    font-size: .7rem;
    text-transform: uppercase;
    color: $color-primary;
    font-weight: 600;
    background-color: $color-secondary;
    display: inline-block;
    padding: .15rem .3rem;
    margin: 0 1rem;
    transform: rotate(-2deg);

    &:first-of-type {
      margin-top: 1rem;
    }
  }
}

.blockquote {
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 1rem;

  &__block {
    display: block;

    &--dash {
      display: none;
    }
  }
}

.paragraph {
  font-size: 1.4rem;
  line-height: 1.3;
  margin: 1rem 1rem .8rem 1rem;
  font-weight: 300;

  &--hashtags {
    font-weight: 400;
    font-size: 1.1rem;
    margin: 1rem 1rem 1.5rem;
  }
}

.link {
  position: relative;
  color: currentColor;
  text-decoration: none;
  transition: all .2s $easingEffectSwift;

  &::after {
    position: absolute;
    z-index: -1;
    left: -2px;
    bottom: -2px;
    height: .25rem;
    width: calc(100% + 4px);
    background: $color-secondary;
    display: inline-block;
    content: "";
    transition: all .2s .2s $easingEffectSwift;
  }

  &:hover {
    &::after {
      height: 1.1rem;
    }
  }
}

.social-list {
  padding: 1rem 1rem .5rem;
  margin: 0 -.7rem;
  display: inline-block;

  &__item {
    list-style-type: none;
    margin: 0 .7rem .5rem;
    height: 65px;
    display: inline-block;
  }

  &__item-link {
    position: relative;
    display: block;
    width: 100%;
    height: 55px;
    text-decoration: none;
    border-bottom: 0;
    transition: all .1s ease;

    &::after {
      display: none;
    }
  }

  &__item-icon {
    width: 30px;
    height: 30px;
    transition: all .1s ease;
  }

  &__item-label {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 60px;
    text-align: center;
    display: inline-block;
    font-size: .7rem;
    text-transform: uppercase;
    transform: translate3d(-50%, 0, 0);
  }
}

// DEVICE : PHABLET

@media screen and (min-width: $phablet) {
  .title {
    &--level-1 {
      font-size: 2.4rem;
    }

    &--level-2 {
      font-size: 1.7rem;
    }
  }

  .social-list {
    &__item {
      margin-bottom: 0;
    }

    &__item-link {
      height: 60px;
    }

    &__item-icon {
      width: 40px;
      height: 40px;
    }
  }
}

// DEVICE : LAPTOP

@media screen and (min-width: $laptop) {
  .blockquote {
    position: relative;

    &__block {
      display: block;
      padding-left: 2.2rem;

      &--dash {
        position: absolute;
        left: -2.2rem;
        top: 0;
        display: inline-block;
      }
    }
  }
}

// DEVICE : DESKTOP

@media screen and (min-width: $desktop) {
  .title {
    &--level-1 {
      font-size: 3.5rem;
    }

    &--level-2 {
      font-size: 1.8rem;
    }
  }

  .social-list {
    margin: 0 -1.1rem;

    &__item {
      margin: 0 1.1rem;
    }
  }
}
