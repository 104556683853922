.footer {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 0 20px 20px;

  &__text {
    font-weight: 400;
    font-size: .8rem;
    color: #fff;
  }

  &__icon {
    font-size: 1.3em;
  }

  @media screen and (min-width: $tablet) {
    position: fixed;
    display: inline-block;
    right: -35px;
    bottom: 70px;
    padding: 0;
    text-align: left;
    transform: rotate(-90deg);
  }

  @media screen and (min-width: $laptop) {
    right: -25px;
    bottom: 80px;
  }

  @media screen and (min-width: $desktop) {
    right: -20px;
    bottom: 95px;
  }
}
