.icon {
  fill: none;
  stroke: currentColor;
  stroke-width: 1.2px;
  transition: all .2s $easingEffectSwift;

  &.icon-wordpress {
    stroke-width: 4px;
  }
}
