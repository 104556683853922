// roboto-condensed-300 - latin

@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src:
    local("Roboto Condensed Light"),
    local("RobotoCondensed-Light"),
    url("../assets/fonts/roboto-condensed-v16-latin-300.woff2") format("woff2"),
    url("../assets/fonts/roboto-condensed-v16-latin-300.woff") format("woff");
}

// roboto-condensed-regular - latin

@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local("Roboto Condensed"),
    local("RobotoCondensed-Regular"),
    url("../assets/fonts/roboto-condensed-v16-latin-regular.woff2") format("woff2"),
    url("../assets/fonts/roboto-condensed-v16-latin-regular.woff") format("woff");
}

/* roboto-condensed-700 - latin */

@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local("Roboto Condensed Bold"),
    local("RobotoCondensed-Bold"),
    url("../assets/fonts/roboto-condensed-v16-latin-700.woff2") format("woff2"),
    url("../assets/fonts/roboto-condensed-v16-latin-700.woff") format("woff");
}

// merriweather-regular - latin

@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local("Merriweather Regular"),
    local("Merriweather-Regular"),
    url("../assets/fonts/merriweather-v19-latin-regular.woff2") format("woff2"),
    url("../assets/fonts/merriweather-v19-latin-regular.woff") format("woff");
}

// merriweather-700 - latin

@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local("Merriweather Bold"),
    local("Merriweather-Bold"),
    url("../assets/fonts/merriweather-v19-latin-700.woff2") format("woff2"),
    url("../assets/fonts/merriweather-v19-latin-700.woff") format("woff");
}

// merriweather-900 - latin

@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src:
    local("Merriweather Black"),
    local("Merriweather-Black"),
    url("../assets/fonts/merriweather-v19-latin-900.woff2") format("woff2"),
    url("../assets/fonts/merriweather-v19-latin-900.woff") format("woff");
}
